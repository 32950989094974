// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4939393d985665eb8e4a1636bd1d87d3853a3dae"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';
import sentryBasedConfigs, {
  isSentryConfigAvailable
} from './sentryBasedConfigs';

if (isSentryConfigAvailable) {
  Sentry.init({
    ...sentryBasedConfigs
  });
}
